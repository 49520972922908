import { createSlice } from "@reduxjs/toolkit";
const locallhostState = createSlice({
    name: "locallhostState",
    initialState: ({
        langaugHost:'tj',
        componentsState: 1,
        loginAdmin: 1,
        adminPublicationState: 1,
        postEditDelete:1
    }),
    reducers: ({
        setLangauge: (state, action) => {state.langaugHost = action.payload},
        setComponentsState: (state, action) => {state.componentsState = action.payload},
        setLoginAdmin: (state, action) => {state.loginAdmin = action.payload},
        setAdminPublicationState: (state, action) => {state.adminPublicationState = action.payload},
        setPostEditDelete: (state, action) => {state.postEditDelete = action.payload},
    }),
})
export const {
    setLangauge,
    setComponentsState,
    setLoginAdmin,
    setAdminPublicationState,
    setPostEditDelete,
} = locallhostState.actions;
export default locallhostState.reducer