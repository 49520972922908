import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css'
import { CommonLoad } from './Components/Loading/Loading';
import { setLoginOpen } from './Redux/reducers/globalState';

const Layout = lazy(()=> import('./Components/Layout/Layout'))
const Information = lazy(()=> import('./Components/Information/Information'))
const InformationCard = lazy(()=> import('./Components/InformationCard/InformationCard'))
const Successes = lazy(()=> import('./Components/Successes/Successes'))
const Recommendations = lazy(()=> import('./Components/Recommendations/Recommendations'))
const About_Us = lazy(()=> import('./Components/About_Us/About_Us'))
const Announcements = lazy(()=> import('./Components/Announcements/Announcements'))
const Reviews = lazy(()=> import('./Components/Reviews/Reviews'))
const Login = lazy(()=> import('./Components/login/Login'))
const Loginpassword = lazy(()=> import('./Components/login/Loginpassword'))
const Page404 = lazy(()=> import('./Components/Page404/Page404'))
function App() {
  const {pathname} = useLocation()
  const loginOpen = useSelector(({globalState})=>globalState.loginOpen)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if(!sessionStorage.getItem("access_token") && pathname.toLowerCase() =='/login') {
      navigate('/loginpassword')
      dispatch(setLoginOpen(false))
    }else if(sessionStorage.getItem("access_token") && pathname.toLowerCase() =='/loginpassword'){
      navigate('/login')
    }
},[pathname,loginOpen])
  return (
    <div className="">
      <Routes>
        <Route path='/' element={<Suspense fallback={<CommonLoad/>}><Layout/></Suspense>}>
          <Route index element={<Suspense fallback={<CommonLoad/>}><Information/></Suspense>}/>
          <Route path='/information/:id' element={<Suspense fallback={<CommonLoad/>}><InformationCard/></Suspense>}/>
          <Route path='/successes' element={<Suspense fallback={<CommonLoad/>}><Successes/></Suspense>}/>
          <Route path='/recommendations' element={<Suspense fallback={<CommonLoad/>}><Recommendations/></Suspense>}/>
          <Route path='/about_us' element={<Suspense fallback={<CommonLoad/>}><About_Us/></Suspense>}/>
          <Route path='/reviews' element={<Suspense fallback={<CommonLoad/>}><Reviews/></Suspense>}/>
          <Route path='/announcements' element={<Suspense fallback={<CommonLoad/>}><Announcements/></Suspense>}/>
          <Route path='*' element={<Suspense fallback={<CommonLoad/>}><Page404/></Suspense>}/>
          <Route path='/login' element={<Suspense fallback={<CommonLoad/>}><Login/></Suspense>}/>
          <Route path='/loginpassword' element={<Suspense fallback={<CommonLoad/>}><Loginpassword/></Suspense>}/>
        </Route>
      </Routes>
    </div>
  );
}
export default App;