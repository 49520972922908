import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosLogin, axiosRequest, saveToken } from "../../Components/axiosRequest";
export const getRecommendations = createAsyncThunk(
  'getRecommendations',
  async (language,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosLogin.get(`/recomendation?lang=${language}`)
      return data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getPublications = createAsyncThunk(
  'getPublications',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosLogin.get(`/publication?publication_type=${body.type}&lang=${body.language}`)
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getReviews = createAsyncThunk(
  'getReviews',
  async (language,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosLogin.get(`/reviews?lang=${language}`)
      return data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getDisbledReviews = createAsyncThunk(
  'getDisbledReviews',
  async (language,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.get(`/disabled/reviews?lang=${language}`)
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postPublicationBody = createAsyncThunk(
  'postPublicationBody',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.post(`publication/create/body`,body.body)
      if(data[0] && body.body.publication_type !== 4){
        if(body.fotoOrVideo == 1){
          dispatch(postPublicationImage({formData:body.formData,publication_id:data[0]}))
        }else{
          dispatch(postPublicationVideo({formData:body.formData,publication_id:data[0]}))
        }
      }else{
        dispatch(setLoginLoading(false))
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postPublicationImage = createAsyncThunk(
  'postPublicationImage',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.post(`publication/upload/img?publication_id=${body.publication_id}`,body.formData,{
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      if(data == '0'){
        dispatch(setLoginLoading(false))
        dispatch(setLoginRefrechAmmountcement(Math.random()))
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postPublicationVideo = createAsyncThunk(
  'postPublicationVideo',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.post(`publication/upload/video?publication_id=${body.publication_id}`,body.formData ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      if(data == '0'){
        dispatch(setLoginLoading(false))
        dispatch(setLoginRefrechAmmountcement(Math.random()))
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postRecomendationBody = createAsyncThunk(
  'postRecomendationBody',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosRequest.post(`recomendation/create`,body.body)
        dispatch(postRecomendationImage({formData:body.formData,recomendation_id:data.data[0],only:false}))
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postRecomendationDoc = createAsyncThunk(
  'postRecomendationDoc',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosRequest.post(`recomendation/upload/doc?recomendation_id=${body.recomendation_id}`,body.formData)
      if(data.data == '0'){
        dispatch(setLoginLoading(false))
        dispatch(setLoginRefrechAmmountcement(Math.random()))
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postRecomendationImage = createAsyncThunk(
  'postRecomendationImage',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosRequest.post(`recomendation/upload/img?recomendation_id=${body.recomendation_id}`,body.formData)
      if(data.data == '0' && !body.only){
        dispatch(postRecomendationDoc({formData:body.formData,recomendation_id:body.recomendation_id}))
      }else{
        dispatch(setLoginLoading(false))
        dispatch(setLoginRefrechAmmountcement(Math.random()))
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAcceptReview = createAsyncThunk(
  'postAcceptReview',
  async (id,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosRequest.post(`review/accept?review_id=${id}`)
      if(data.data == '0'){
        dispatch(setLoginLoading(false))
        dispatch(setLoginRefrechAmmountcement(Math.random()))
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteReview = createAsyncThunk(
  'deleteReview',
  async (id,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosRequest.post(`/review/delete?review_id=${id}`)
      if(data.data == '0'){
        dispatch(setLoginLoading(false))
        dispatch(setLoginRefrechAmmountcement(Math.random()))
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postReviews = createAsyncThunk(
  'postReviews',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const data = await axiosLogin.post(`review/create?from_user=${body.url.fullname}&organization_name=${body.url.company}&body=${body.url.body}`,body.formData)
      dispatch(setReviewsOpen(false))
      dispatch(setReviewState(true))
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postLoginAdmin = createAsyncThunk(
  'postLoginAdmin',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosLogin.post(`login`,body)
        saveToken(data.access_token, data.refresh_token)
        dispatch(setLoginOpen(true))
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deletePublication = createAsyncThunk(
  'deletePublication',
  async (id,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.post(`publication/delete/body?publication_id=${id}`,'')
      dispatch(setLoginLoading(false))
      dispatch(setLoginRefrechAmmountcement(Math.random()))
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteRecommendation = createAsyncThunk(
  'deleteRecommendation',
  async (id,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.post(`recomendation/delete?recomendation_id=${id}`,'')
      dispatch(setLoginLoading(false))
      dispatch(setLoginRefrechAmmountcement(Math.random()))
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const editPublicationsBody = createAsyncThunk(
  'editPublicationsBody',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.post(`publication/update/body?publication_id=${body.id}`,body.body)
      dispatch(setLoginLoading(false))
      dispatch(setLoginRefrechAmmountcement(Math.random()))
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const editRecommendationBody = createAsyncThunk(
  'editRecommendationBody',
  async (body,{dispatch, rejectWithValue}) => {
    try {
      const {data} = await axiosRequest.post(`recomendation/update?recomendation_id=${body.id}`,body.body)
      dispatch(setLoginLoading(false))
      dispatch(setLoginRefrechAmmountcement(Math.random()))
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
const globalState = createSlice({
    name: "globalState",
    initialState: ({
        reviewsOpen: false,
        recommendationsData: [],
        publicationsInformationData: [],
        publicationsRecommendationnData: [],
        publicationsProjectData: [],
        publicationsAnnouncementData: [],
        publicationsData: [],
        reviewsData: [],
        disabledReviewsData: [],
        loadings: false,
        EditorStateTJ: '',
        EditorStateRU: '',
        EditorStateEN: '',
        EditorLanguage: 1,
        reviewState: false,
        closeEditor: false,
        loginLoading: false,
        loginToken: {},
        loginOpen: false, 
        loginRefreshAmmouncement: null,
        loginError: false,
    }),
    reducers: ({
        setReviewsOpen: (state, action) => {state.reviewsOpen = action.payload},
        setEditorStateTJ: (state, action) => {state.EditorStateTJ = action.payload},
        setEditorStateRU: (state, action) => {state.EditorStateRU = action.payload},
        setEditorStateEN: (state, action) => {state.EditorStateEN = action.payload},
        setEditorLanguage: (state, action) => {state.EditorLanguage = action.payload},
        setCloseEditor: (state, action) => {state.closeEditor = action.payload},
        setReviewState: (state, action) => {state.reviewState = action.payload},
        setLoginLoading: (state, action) => {state.loginLoading = action.payload},
        setLoginToken: (state, action) => {state.loginToken = action.payload},
        setLoginOpen: (state, action) => {state.loginOpen = action.payload},
        setLoginRefrechAmmountcement: (state, action) => {state.loginRefreshAmmouncement = action.payload} ,
        setLoginError: (state, action) => {state.loginError = action.payload},
    }),
    extraReducers: ({
      [getRecommendations.pending]: (state,action) => {
        state.recommendationsData = []
        state.loadings = true
      },
      [getRecommendations.fulfilled]: (state ,action) => {
        state.recommendationsData = action.payload;
        state.loadings = false
      },
      [getRecommendations.rejected]: (state,action) => {
        state.recommendationsData = [];
        state.loadings = false
      },
      [getPublications.pending]: (state,action) => {
        state.publicationsData = []
        state.loadings = true
      },
      [getPublications.fulfilled]: (state ,action) => {
        state.publicationsData = action.payload
        state.loadings = false
      },
      [getPublications.rejected]: (state,action) => {
        state.publicationsData = []
        state.loadings = false
      },
      [getReviews.pending]: (state,action) => {
        state.reviewsData = []
        state.loadings = true
      },
      [getReviews.fulfilled]: (state ,action) => {
        state.reviewsData = action.payload;
        state.loadings = false
      },
      [getReviews.rejected]: (state,action) => {
        state.reviewsData = [];
        state.loadings = false
      },
      [getDisbledReviews.pending]: (state,action) => {
        state.disabledReviewsData = []
        state.loadings = true
      },
      [getDisbledReviews.fulfilled]: (state ,action) => {
        state.disabledReviewsData = action.payload;
        state.loadings = false
      },
      [getDisbledReviews.rejected]: (state,action) => {
        state.disabledReviewsData = [];
        state.loadings = false
      },
      [postPublicationBody.pending]: (state,action) =>  {state.loadings = true },
      [postPublicationBody.fulfilled]: (state ,action) => { state.loadings = false },
      [postPublicationBody.rejected]: (state,action) => { state.loadings = false },
      [postPublicationImage.pending]: (state,action) =>  {state.loadings = true },
      [postPublicationImage.fulfilled]: (state ,action) => { state.loadings = false },
      [postPublicationImage.rejected]: (state,action) => { state.loadings = false },
      [postPublicationVideo.pending]: (state,action) =>  {state.loadings = true },
      [postPublicationVideo.fulfilled]: (state ,action) => { state.loadings = false },
      [postPublicationVideo.rejected]: (state,action) => { state.loadings = false },
      [postRecomendationBody.pending]: (state,action) =>  {state.loadings = true },
      [postRecomendationBody.fulfilled]: (state ,action) => { state.loadings = false },
      [postRecomendationBody.rejected]: (state,action) => { state.loadings = false },
      [postRecomendationDoc.pending]: (state,action) =>  {state.loadings = true },
      [postRecomendationDoc.fulfilled]: (state ,action) => { state.loadings = false },
      [postRecomendationDoc.rejected]: (state,action) => { state.loadings = false },
      [postAcceptReview.pending]: (state,action) =>  {state.loadings = true },
      [postAcceptReview.fulfilled]: (state ,action) => { state.loadings = false },
      [postAcceptReview.rejected]: (state,action) => { state.loadings = false },
      [deleteReview.pending]: (state,action) =>  {state.loadings = true },
      [deleteReview.fulfilled]: (state ,action) => { state.loadings = false },
      [deleteReview.rejected]: (state,action) => { state.loadings = false },
      [postReviews.pending]: (state,action) =>  {state.loadings = true },
      [postReviews.fulfilled]: (state ,action) => { state.loadings = false },
      [postReviews.rejected]: (state,action) => { state.loadings = false },
      [postRecomendationImage.pending]: (state,action) =>  {state.loadings = true },
      [postRecomendationImage.fulfilled]: (state ,action) => { state.loadings = false },
      [postRecomendationImage.rejected]: (state,action) => { state.loadings = false },
      [postLoginAdmin.pending]: (state,action) =>  {state.loadings = true },
      [postLoginAdmin.fulfilled]: (state ,action) => { state.loadings = false },
      [postLoginAdmin.rejected]: (state,action) => { state.loadings = false },
      [deletePublication.pending]: (state,action) =>  {state.loadings = true },
      [deletePublication.fulfilled]: (state ,action) => { state.loadings = false },
      [deletePublication.rejected]: (state,action) => { state.loadings = false },  
      [deleteRecommendation.pending]: (state,action) =>  {state.loadings = true },
      [deleteRecommendation.fulfilled]: (state ,action) => { state.loadings = false },
      [deleteRecommendation.rejected]: (state,action) => { state.loadings = false },
      [editPublicationsBody.pending]: (state,action) =>  {state.loadings = true },
      [editPublicationsBody.fulfilled]: (state ,action) => { state.loadings = false },
      [editPublicationsBody.rejected]: (state,action) => { state.loadings = false },
      [editRecommendationBody.pending]: (state,action) =>  {state.loadings = true },
      [editRecommendationBody.fulfilled]: (state ,action) => { state.loadings = false },
      [editRecommendationBody.rejected]: (state,action) => { state.loadings = false },
    }),
})
export const {
    setReviewsOpen,
    setEditorStateTJ,
    setEditorStateRU,
    setEditorStateEN,
    setEditorLanguage,
    setCloseEditor,
    setReviewState,
    setLoginLoading,
    setLoginToken,
    setLoginOpen,
    setLoginRefrechAmmountcement,
    setLoginError
} = globalState.actions;
export default globalState.reducer