import { combineReducers, configureStore, getDefaultMiddleware, } from "@reduxjs/toolkit";
import { 
    persistStore, 
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
 } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import globalRequest from "../reducers/globalRequest";
import globalState from "../reducers/globalState";
import locallhostState from "../reducers/locallhostState";
const rootReducer = combineReducers({
    globalState: globalState,
    locallhostState: locallhostState,
    [globalRequest.reducerPath]: globalRequest
})
const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['globalState', 'globalRequest'],
}
const persistReducerr = persistReducer(persistConfig, rootReducer)
const store = configureStore({
    reducer: persistReducerr,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat(globalRequest.middleware)
})
export const persistor = persistStore(store)
export default store